@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
body {
  font-family:  'Raleway', sans-serif;
  background-color: #fcfcfc;
}

.home-background {
  height: 700px;
  background-image: url(/static/media/richardsonstreet1.bbd04c4b.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.chat-area, .home, .profiles-section, .profile-form-section {
  padding-top: 70px;
}

.chat-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.chat-area .chat-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.chat-bubble .chat-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.chat-area .chat-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 90%;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .chat-area .chat-bubble {
    max-width: 250px;
  }
}

.contact-details-sliding-pane {
  margin-top: 80px;
}

.form_label {
  font-size: small;
}

.chat-section {
  max-width: 70%;
}

.profiles-section{
  max-width: 20%;
  text-align: right;
}

